<template>
  <div class="work-order-gather">
    <van-nav-bar left-arrow left-text="返回" @click-left="goBack" :title="moduleTitle" />
    <van-radio-group v-model="selDateType" direction="horizontal" class="date-radio">
      <van-radio name="1">七天</van-radio>
      <van-radio name="2">近一月</van-radio>
      <van-radio name="3">自定义</van-radio>
      <van-button type="primary" v-show="selDateType !== '3'" @click="onConfirm">查询</van-button>
    </van-radio-group>
    <van-cell-group class="date-cell-group" v-show="selDateType === '3'">
      <van-cell title="选择日期区间" :value="selDate" @click="show = true" is-link />
      <van-calendar v-model="show" type="range" @confirm="onConfirm" :min-date="minDate" :allow-same-day="true" />
    </van-cell-group>

    <template v-for="drawDiv in drawDivs">
      <div :id="'drawDiv' + drawDiv" style="width: 100%;height:400px;" class="draw-div"></div>
      <div :id="'drawDivTotal' + drawDiv" style="width: 100%;height:400px;" class="draw-div"></div>
    </template>
  </div>
</template>

<script>
export default {
  name: "Analysis1006",
  data() {
    return {
      moduleTitle: "",
      selDateType: "1",
      minDate: new Date(2020, 1, 1),
      show: false,
      miniReqParams: {
        //0=今天，1=时间区间
        timeConditionType: "0",
        startDate: "",
        endDate: "",
        month: "",
      },
      drawDivs: [],
      existCharts: [],
      totalDiv: false,
    };
  },
  methods: {
    goBack() {
      this.$router.back();
    },
    onConfirm(date) {
      if (this.selDateType === "3") {
        const [start, end] = date;
        this.show = false;
        this.miniReqParams.timeConditionType = "1";
        this.miniReqParams.startDate = this.dateUtils.formatDate(start);
        this.miniReqParams.endDate = this.dateUtils.formatDate(end);
      } else {
        if (this.selDateType === "1") {
          this.miniReqParams.startDate = this.dateUtils.getBeforeDate(7);
          this.miniReqParams.endDate = this.dateUtils.formatDate(new Date());
        } else {
          this.miniReqParams.startDate = this.dateUtils.getBeforeDate(30);
          this.miniReqParams.endDate = this.dateUtils.formatDate(new Date());
        }
      }
      this.$toast.loading({ message: "请求中", overlay: true });
      this.sendQuery();
    },
    sendQuery() {
      //发送查询请求
      this.postRequest(
        "/wechat-analysis/test-order-gather",
        this.miniReqParams
      ).then((resp) => {
        // console.log(resp);
        if (resp) {
          if (this.existCharts.length > 0) {
            this.existCharts.forEach((charts) => {
              charts.dispose();
            });
            this.existCharts = [];
          }
          let data = resp.data;
          if (data) {
            this.drawDivs = Object.keys(data.dataList);
            this.$nextTick(() => {
              this.drawCharts(data, false);
            });
          } else {
            this.$toast("查询无数据，请换条件查询！");
          }
        }
      });
    },
    drawCharts(data) {
      let dataSourceGroup = ["广佛系统", "总部系统"];
      let legendGroup = [
        "安装工单",
        "安装设备台数",
        "辅材电子支付单数",
        "维修工单",
        "维修台数",
        "保外维修电子支付单数",
      ];
      data.dataList.forEach((website, websiteIndex) => {
        let key = Object.keys(website);
        let params = {};
        params.showDates = data.xaxis;
        dataSourceGroup.forEach((dataSource, dataSourceIndex) => {
          let title =
            data.websiteNumberSet[websiteIndex].substring(8, 10) +
            data.websiteNumberSet[websiteIndex].substring(
              21,
              data.websiteNumberSet[websiteIndex].length
            );
          params.title = title + "-" + dataSourceGroup[dataSourceIndex];
          params.legend = legendGroup;
          params.series = [];
          //插入安装工单
          params.series.push({
            name: legendGroup[0],
            type: "line",
            data: website[key]["orderNumber" + (dataSourceIndex + 1)],
          });
          //插入安装设备台数
          params.series.push({
            name: legendGroup[1],
            type: "line",
            data: website[key]["machineNum" + (dataSourceIndex + 1)],
          });
          //插入辅材电子支付单数
          params.series.push({
            name: legendGroup[2],
            type: "line",
            data: website[key]["cutFc" + (dataSourceIndex + 1)],
          });
          //插入维修工单
          params.series.push({
            name: legendGroup[3],
            type: "line",
            data: website[key]["orderNumberRepair" + (dataSourceIndex + 1)],
          });
          //插入维修台数
          params.series.push({
            name: legendGroup[4],
            type: "line",
            data: website["machineNumRepair" + (dataSourceIndex + 1)],
          });
          //插入保外维修电子支付单数
          params.series.push({
            name: legendGroup[5],
            type: "line",
            data: website[key]["cutWx" + (dataSourceIndex + 1)],
          });
          console.log(params);
          if (dataSourceIndex == 0) {
            this.myEcharts("drawDiv" + websiteIndex, params);
          } else {
            this.myEcharts("drawDivTotal" + websiteIndex, params);
          }
        });
      });
    },
    myEcharts(id, params) {
      // 基于准备好的dom，初始化echarts实例
      let myChart = this.$echarts.init(document.getElementById(id));

      // 指定图表的配置项和数据
      let option = {
        title: {
          text: params.title,
        },
        tooltip: {
          trigger: "axis",
          confine: true,
        },
        legend: {
          show: true,
          top: "6%",
          data: params.legend,
        },
        grid: {
          top: "20%",
          left: "3%",
          right: "11%",
          bottom: "1%",
          containLabel: true,
        },
        xAxis: {
          type: "category",
          boundaryGap: false,
          data: params.showDates,
        },
        yAxis: [
          {
            type: "value",
            axisTick: {
              inside: true,
            },
            scale: true,
            axisLabel: {
              margin: 2,
              formatter: function (value, index) {
                //y轴的数值显示格式化主要调整部分
                if (value >= 10000 && value < 10000000) {
                  value = value / 10000 + "万";
                }
                return value;
              },
            },
            splitLine: {
              //网格线 默认true
              show: true,
            },
          },
        ],
        series: params.series,
      };

      // 使用刚指定的配置项和数据显示图表。
      myChart.setOption(option);
      this.existCharts.push(myChart);
    },
  },
  computed: {
    selDate() {
      if (
        this.miniReqParams.startDate.length > 0 &&
        this.miniReqParams.endDate.length > 0
      ) {
        return `${this.miniReqParams.startDate}-${this.miniReqParams.endDate}`;
      }
    },
  },
  mounted: function () {
    this.moduleTitle = window.sessionStorage.getItem("moduleTitle");
  },
};
</script>

<style scoped>
.date-radio {
  margin-top: 15px;
  background-color: #fff;
  padding: 10px 16px;
}
.date-radio > button {
  margin-left: auto;
}
.draw-div {
  margin-top: 15px;
  background-color: white;
}
.van-nav-bar__content {
  margin-bottom: 10px;
}
.work-order-gather {
  padding-bottom: 15px;
  width: 100%;
}
.date-cell-group {
  margin: 15px 0;
}
.van-cell__value {
  color: #999;
}
</style>
